<template>
  <div class="content-wrapper">
    <bo-page-title>
        <template slot="additionalCta">
            <b-button v-if="!isList" @click="doCetakSEP(row)" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
              <b><i class="icon-printer"></i></b>
                Cetak SEP
            </b-button>
        </template>
    </bo-page-title>
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="8">
                    <div class="form-group mb-0 d-flex">
                        <date-range-picker ref="picker" :locale-data="datePickerConfig.locale"
                            :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'"
                            :ranges="datePickerConfig.ranges" @update="updateValues">
                            <template v-slot:input="picker">
                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                            </template>
                        </date-range-picker>
                        <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                    </div>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="4">
              <SearchInput :value.sync="filter.search" @search="doFill" />
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>
            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>
            <template
              #cell(absd_tgl_sep) = "data"
            >
                <span>{{data.value | moment("DD MMMM YYYY")}}</span>
            </template>
            
            <template
              #cell(absd_tgl_rujuk) = "data"
            >
                <span v-if="data.value">{{data.value | moment("DD MMMM YYYY")}}</span>
                <span v-else> - </span>
            </template>
                        
            <template
              #cell(absd_jenis) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.jenisPelayanan,data.value)|| "-"}}</span>
            </template>
            
            <template
              #cell(absd_kelas_rawat) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.kelasRawat,data.value)|| "-"}}</span>
            </template>
            
            <template
              #cell(absd_naik_kelas) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template>

            
            <template
              #cell(absd_kd_pelayanan) = "data"
            >
                <span>{{data.value|| "-"}} + {{data.item.mpo_name||"-"}}</span>
            </template>

            <template
              #cell(absd_pembiayaan) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template>
            
            <template
              #cell(absd_peserta) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template>

            <template
              #cell(absd_tanggal_pulang) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template>
            
            <template
              #cell(absd_tanggal_kll) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template>
            
            <template
              #cell(absd_keterangan_kll) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template>


            <template
              #cell(ap_gender) = "data"
            >
                <span>{{(data.value == 1 ? 'Laki-Laki' : 'Perempuan' )|| "-"}}</span>
            </template>

            <template
              #cell(absd_laka_lantas) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.configLakaLantas,data.value)|| "-"}}</span>
            </template>    

            <template
              #cell(absd_asal_rujukan) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.jenisFaskes,data.value)|| "-"}}</span>
            </template>      
            
            <template
              #cell(absd_eksekutif) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.yesNoOptV3,data.value)|| "-"}}</span>
            </template>         
            
            <template
              #cell(absd_cob) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.yesNoOptV3,data.value)|| "-"}}</span>
            </template> 
            
            <template
              #cell(absd_katarak) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.yesNoOptV3,data.value)|| "-"}}</span>
            </template>  
            
            <template
              #cell(absd_is_kll) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.yesNoOptV3,data.value)|| "-"}}</span>
            </template>    
            
            
            <template
              #cell(absd_tujuan_kunjungan) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.configTujuan,data.value)|| "-"}}</span>
            </template>  
            
            <template
              #cell(absd_flag) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.configFlag,data.value)|| "-"}}</span>
            </template>    
            
            <template
              #cell(absd_penunjang) = "data"
            >
                <span>{{getConfigDynamic(Config.mr.configPenunjang,data.value)|| "-"}}</span>
            </template>  

            <template
              #cell(absd_ppk_rujukan) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template> 

            <template
              #cell(absd_no_rujuk) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template> 
            
            <template
              #cell(absd_no_sep_suplesi) = "data"
            >
                <span>{{data.value|| "-"}}</span>
            </template>   
            
            <template
              #cell(absd_is_surat_kontrol) = "data"
            >
                <span v-if="data.item.absd_is_surat_kontrol == 'Y'">SEP Surat Kontrol</span>
                <span v-if="data.item.absd_is_sep_internal == 'Y'">SEP Internal</span>
                <span v-if="!data.item.absd_is_surat_kontrol && !data.item.absd_is_sep_internal"> SEP Reguler </span>
            </template>         
                  
            

            

            <template
              #cell(action)="data"
            >
              <div class="list-icons">
                <router-link class="list-icons-item"
                  v-if="moduleRole('edit')"
                  v-b-tooltip.hover.right :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
                  data-toggle="tooltip" data-placement="top" title="Edit"><i
                    class="icon-pencil5"></i></router-link>
                    
                <router-link class="list-icons-item"
                  v-if="moduleRole('view')"
                  v-b-tooltip.hover.right :to="{name: $route.name, params: {pageSlug: data.item[idKey]}, query:{ detail: true}}"
                  data-toggle="tooltip" data-placement="top" title="View"><i
                    class="icon-eye"></i></router-link>

                <a href="javascript:;" class="list-icons-item"
                  v-b-tooltip.hover.right v-if="moduleRole('delete')"
                  @click="doDelete(data.index, data.item)"
                  data-toggle="tooltip" data-placement="top" title="Delete"><i
                    class="icon-bin"></i></a>

                    
                <a href="javascript:;" class="list-icons-item"
                  v-b-tooltip.hover.right v-if="data.item.absd_no_sep"
                 @click="doCetakSEP(data.item)"
                  data-toggle="tooltip" data-placement="top" title="Cetak"><i
                    class="icon-printer"></i></a>
              </div>
              <span v-if="!moduleRole('view')">-</span>
            </template>
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
      <template v-else>
        <FormDetail v-if="$route.query.detail" :row.sync="row" v-bind="passToSub" />
        <Form v-else :row.sync="row" v-bind="passToSub" />      
      </template>
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
import FormDetail from './FormDetail.vue'
const _ = global._
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import $ from 'jquery'
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components:{Form,DateRangePicker,FormDetail},
  data() {
    return {
        idKey:'absd_id',
        statusKey:'absd_is_active',
        fields: [
            {
                key: 'number',
                label: '#',
            },
            {
                key: 'absd_no_sep',
                label: 'No SEP',
            },
            {
                key: 'ap_fullname',
                label: 'NAMA PASIEN',
            },
            {
                key: 'ap_code',
                label: 'Nomor Rekam Medis',
            },
            {
                key: 'absd_tgl_sep',
                label: 'TANGGAL SEP',
            },
            {
                key: 'absd_tgl_rujuk',
                label: 'TGL. RUJUKAN',
            },
            {
                key: 'absd_no_rujuk',
                label: 'NO. RUJUKAN',
            },
            {
                key: 'absd_ppk_rujukan',
                label: 'NAMA PPK RUJUKAN',
            },
            {
                key: 'absd_jenis',
                label: 'JENIS',
            },
            {
                key: 'absd_diagnosa_pelayanan',
                label: 'NAMA DIAGNOSA',
            },
            {
                key: 'absd_kd_pelayanan',
                label: 'KODE POLI + NAMA POLI',
            },
            {
                key: 'absd_dpjp_name',
                label: 'Dokter DPJP',
            },
            {
                key: 'absd_is_surat_kontrol',
                label: 'Keterangan',
            },
            {
                key: 'action',
                label: 'Aksi',
            },
        ],
        mPasien : [],
        mPoli : [],
        mProvinsi : [],
        mKabupaten : [],
        mKota : [],
        dataReg : [],
        mDokter : [],

        dateRange: {
            startDate: new Date(),
            endDate: new Date(),
        },
        datePickerConfig: {
            startDate: new Date(),
            endDate: new Date(),
            autoApply: true,
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        }
    }
  },
  computed:{
    passToSub(){
        return _.assign(this.passToSubComp||{},{
          mPasien: this.mPasien,
          mPoli: this.mPoli,
          mProvinsi: this.mProvinsi,
          mKabupaten: this.mKabupaten,
          mKota: this.mKota,
          mDokter: this.mDokter,
          dataReg: this.dataReg
        })
    }
  },
  
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    doCetakSEP(v){
      console.log(v)
      let data = {exptype: 'xlsx', type: "export", id: v.absd_id}
      let self = this

      $.ajax({
          type: "POST",
          url: process.env.VUE_APP_API_URL + `/report/${'downloadSEP'}?token=IXs1029102asoaksoas102901290`,
          data: data,
          cache: false,
          xhrFields:{
              responseType: 'blob'
          },
          success: data => 
          {
              self.loadingOverlay = false
              var link = document.createElement('a')
              link.href = window.URL.createObjectURL(data)
              link.download = `Laporan-SEP-${moment().format("YYYY-MM-DD")}.pdf`
              link.click()
          },
          fail: data => {
              self.loadingOverlay = false
              alert('Not downloaded')
          }
      })
    },

    doFill(){
      this.doFilter()
    },
    doConvertDate(){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    updateValues(e){
        this.doConvertDate()
        this.doFill()
    },
    getConfigDynamic(master, value) {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
            text = master[index]['text']
            }
        }
        return text
    },
  },
  
    filters: {
        date(val) {
        return val ? moment(val).format("D MMM YYYY") : ""
        }
    },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>